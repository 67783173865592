<template>
  <screen_width_cont>
    <div class="myBodyGeneral">
      <div class="myHeader_vertical" ref="myHeader_cotizMotivo">
        <div class="coti_motivo_header">
          <div class="cont_300_basico ancho_acotado">
            <div class="header_top">
              <headerCerrarInfoCuenta
                @callCuenta="callCuentaLocal"
                @callInfo="callInfoLocal"
                @cancelar="cancelarLocal"
              />
            </div>
          </div>
          <div class="cotizadorMotivoRemesaTitle title title_margin_top">
            {{ t("app.cotizadorMotivoRemesa.tit") }}
          </div>
        </div>
      </div>

      <blurComp
        class="blur_sup"
        :style="blur_sup_cotizMotivo"
        :scroll_to_="scroll_to_top"
        tipo="sup"
      />

      <div
        class="contenido centrado"
        :style="contenido_cotizMotivo"
        v-show="cont_visible"
        @scroll="onScroll"
        ref="contenido_cotiMotivo"
      >
        <div class="coti_motivo_body">
          <div class="cont_300_basico ancho_acotado">
            <div class="cotizadorMotivoRemesaText">
              {{ t("app.cotizadorMotivoRemesa.text_1") }}
            </div>

            <div class="cotizadorMotivoRemesaSubText">
              {{ t("app.cotizadorMotivoRemesa.text_2") }}
            </div>
          </div>

          <div class="cont_300_basico ancho_acotado">
            <div class="cotizadorMotivoRemesaSelect">
              <selectBoxCotizador
                :placeHolder="t('app.cotizadorMotivoRemesa.select_placeHolder')"
                requerido="*"
                :config="{ mostrarLabel: false, requerido: 'l' }"
                @dispatchChangeData="dispatchChangeDataLocal"
                :mensajeError="[
                  t('configuracion.data.textos_errores.campoObligatorio'),
                  '',
                ]"
                :disabled="false"
                :data="opcionesMotivosRemesa"
                tabindex="0"
                :error="error"
                :motivoRemesaPreSelected="motivoRemesaPreSelected"
              />
            </div>
          </div>

          <div class="cotizadorMotivoRemesaBtn_container">
            <div class="cotizadorMotivoRemesaBtn">
              <btnAction
                :textBtn="t('app.comunes.btns.continuar')"
                @action="registrarMotivoRemesa"
                colorBack="azul"
                colorText="blco"
                :estadoBtn="estadoBtn"
                :msg_completar="t('app.comunes.btns.select_motivo')"
              />
            </div>

            <div class="cotizadorMotivoRemesaEtapas">
              <pasosGiro paso="3" totales="6" />
            </div>
          </div>
        </div>
      </div>

      <blurComp
        class="blur_inf"
        :style="blur_inf_cotizMotivo"
        :scroll_to_="scroll_to_bottom"
        tipo="inf"
      />

      <div class="footer_bottom" ref="footer_cotizMotivo">
        <div class="cotizadorMotivoRemesaVolverCont">
          <volver @volver_emit="volver" />
        </div>

        <div class="footer_env_seguro">
          <enviosSeguros @callEnvioSeguroModal_="callEnvioSeguroModal" />
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>


<script>
import { modalController, menuController } from "@ionic/vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { useI18n } from "vue-i18n";
import headerCerrarInfoCuenta from "../components/generales/headerCerrarInfoCuenta.vue";
import btnAction from "../components/generales/btnAction.vue";
import pasosGiro from "../components/generales/pasosProceso.vue";
import selectBoxCotizador from "../components/simulador/selectBoxCotizador.vue";
import envioSeguroHome from "./modales/enviosSegurosHome.vue";
import enviosSeguros from "../components/generales/envios_seguros.vue";
import volver from "../components/generales/volver.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";
import blurComp from "../components/generales/blur.vue";
import {
  blur_sup_scroll,
  blur_inf_scroll,
  contenido_style,
  blur_sup_style,
  blur_inf_style,
} from "../assets/funciones/blur";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "cotizadorMotivoRemesa",
  components: {
    headerCerrarInfoCuenta,
    btnAction,
    pasosGiro,
    selectBoxCotizador,
    enviosSeguros,
    volver,
    screen_width_cont,
    blurComp,
  },
  data() {
    return {
      // dataMotivoRemasa: {},
      // opcionesMotivosRemesa: [],
      motivo_transaccion: "",
      primeraPasada: true,
      refrescarSelectSimple: false,
      error: false,
      motivoRemesaPreSelected: "",
      header: 0,
      footer: 0,
      cont_visible: false,
      scroll_to_bottom: true,
      scroll_to_top: true,
      contenido_cotizMotivo: {},
      blur_sup_cotizMotivo: {},
      blur_inf_cotizMotivo: {},
    };
  },
  computed: {
    ...mapState([
      "configuracion",
      "transaccion",
      "desde",
      "widthScreen",
      "heigthScreen",
      "widthScreen",
      "plataforma",
      "nav_stack",
      "procesoGeneral",
      "menuSpDesde",
      "monto_inicio",
      "pais_destino_inicial",
    ]),

    // btnActivo() {
    //   return !this.dataMotivoRemasa.tieneError;
    // },
    opcionesMotivosRemesa() {
      // console.log(this.transaccion);
      let aux = [];
      if (this.configuracion) {
        this.configuracion.data.auxiliares.motivo_transaccion[0].forEach(
          (motivo) => {
            if (
              this.transaccion.forma_entrega_id == "1" ||
              this.transaccion.forma_entrega_id == "2" ||
              (this.transaccion.forma_entrega_id == "6" &&
                this.transaccion.pais_destino.split("_")[0] == "900")
            ) {
              if (motivo[0] == "0" || motivo[0] == "1") {
                aux.push([motivo[0], motivo[1]]);
              }
            } else if (
              this.transaccion.forma_entrega_id == "6" &&
              this.transaccion.pais_destino.split("_")[0] == "400"
            ) {
              if (
                motivo[0] == "001" ||
                motivo[0] == "002" ||
                motivo[0] == "003" ||
                motivo[0] == "004"
              ) {
                aux.push([motivo[0], motivo[1]]);
              }
            }
          }
        );
      }
      // console.log(aux);
      return aux;
    },
    estadoBtn() {
      let aux = "inactive";
      if (this.motivo_transaccion && this.motivo_transaccion != "") {
        aux = "active";
      }
      return aux;
    },
  },
  methods: {
    ...mapMutations([
      "updateTransaccion",
      "updateResumen",
      "updateDesde",
      "updateBloquearNavegacion",
      "updateMenuSpDesde",
      "setshowSpinner",
      "set_nav_stack",
      "reset_transaccion",
      "set_pre_values_cotizador",
    ]),
    ...mapActions(["limpiarTransaccion"]),

    callCuentaLocal() {
      this.updateMenuSpDesde({ desde: "cotizadorMotivoRemesa", menuSp: true });
      menuController.open("firstMenu");
    },
    callInfoLocal() {
      this.updateMenuSpDesde({ desde: "cotizadorMotivoRemesa", menuSp: true });

      this.set_nav_stack({
        desde: ["cotizadorMotivoRemesa"],
        menuSp: this.nav_stack.menuSp,
      });

      this.$router.push({
        name: "centroAyuda",
        params: { tema: "0" },
        replace: true,
      });
    },
    cancelarLocal() {
      // this.updateTransaccion({
      //   moneda_destino_sigla: "",
      //   banco: "",
      //   id_banco_destino: "",
      //   tipo_cuenta: "",
      //   cuenta_corriente: "",
      //   motivo_transaccion: "",
      //   numero_cuenta: "",
      //   id_persona: "",
      //   forma_entrega_id: "",
      //   tasa_mostrar: "",
      //   detalle_entrega_id: "",
      // });
      this.reset_transaccion();
      this.set_pre_values_cotizador({
        monto_inicio: this.monto_inicio,
        pais_destino_inicial: this.pais_destino_inicial,
      });
      if (this.menuSpDesde.desde == "cotizadorDestinatario") {
        this.$router.push({ name: "home", replace: true });
      } else if (this.menuSpDesde.desde == "ingresar_giro") {
        this.$router.push({ name: "destinatarios", replace: true });
      } else if (this.menuSpDesde.desde == "repetir_giro") {
        this.$router.push({ name: "home", replace: true });
      }
    },
    volver() {
      // console.log(this.desde, this.nav_stack);
      // this.$router.push({ name: this.procesoGeneral, replace: true });
      this.$router.push({ name: this.menuSpDesde.desde, replace: true });
    },
    async callEnvioSeguroModal() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: envioSeguroHome,
        // cssClass: 'my-custom-class',
        // componentProps: {
        //   dataTransfer: data
        // },
      });
      modal.onDidDismiss().then(() => {
        this.updateBloquearNavegacion(false);
      });
      return modal.present();
    },

    dispatchChangeDataLocal(data) {
      
      this.motivo_transaccion = data[0];
      this.error = false;
      
    },
    

    registrarMotivoRemesa() {
      
      this.error = false;
      if (!this.motivo_transaccion || this.motivo_transaccion == "") {
        this.error = true;
        return;
      }
      
      this.updateTransaccion({
        motivo_transaccion: this.motivo_transaccion,
      });
      this.setshowSpinner(false);
      this.$router.push({ name: "cotizadorResumen", replace: true });
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.scroll_to_top = blur_sup_scroll(scrollTop);
      this.scroll_to_bottom = blur_inf_scroll(
        scrollTop,
        clientHeight,
        scrollHeight
      );
    },
  },
  watch: {
    heigthScreen() {
      this.contenido_cotizMotivo = contenido_style(
        this.heigthScreen,
        this.header,
        this.footer,
        this.plataforma == "web" ? 63 : 0
      );
    },
  },
  async ionViewWillEnter() {
    this.primeraPasada = false;
   
  },
  ionViewDidEnter() {
    
    // this.updateDesde("motivoRemesa");

    if (this.transaccion.motivo_transaccion) {
      this.motivoRemesaPreSelected = this.transaccion.motivo_transaccion;
     
    }
    this.$nextTick(() => {
      setTimeout(() => {
        this.header = this.$refs["myHeader_cotizMotivo"].offsetHeight;
        this.footer = this.$refs["footer_cotizMotivo"].offsetHeight;
        this.cont_visible = true;
        this.contenido_cotizMotivo = contenido_style(
          this.heigthScreen,
          this.header,
          this.footer,
          this.plataforma == "web" ? 63 : 0
        );
        this.blur_sup_cotizMotivo = blur_sup_style(this.header);
        this.blur_inf_cotizMotivo = blur_inf_style(this.footer);
      }, 100);

      setTimeout(() => {
        if (
          this.$refs["contenido_cotiMotivo"].scrollHeight >
          this.$refs["contenido_cotiMotivo"].clientHeight
        ) {
          this.scroll_to_bottom = false;
        }
      }, 100);

    });
  },
  ionViewWillLeave() {
    // this.opcionesMotivosRemesa = [];
  },
};
</script>

<style>
.coti_motivo_header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 35rem;
  /* padding: 0 2rem; */
}

.cotizadorMotivoRemesaTitle {
  text-align: center;
  color: #801b1c;
}

.cotizadorMotivoRemesaText {
  margin-top: 1.25rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1px;
  color: #787878;
}

.cotizadorMotivoRemesaSubText {
  margin-top: 2.9375rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  text-align: center;
  color: #787878;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}

.cotizadorMotivoRemesaSelect {
  margin-top: 1.3125rem;
  padding: 0 2rem;
  width: 100%;
}

.cotizadorMotivoRemesaBtn_container {
  padding-top: 4rem;
  width: 100%;
  max-width: 35rem;
}

.cotizadorMotivoRemesaBtn {
  /* margin-top: 7rem; */
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}

.cotizadorMotivoRemesaEtapas {
  margin-top: 1rem;
}

.cotizadorMotivoRemesaVolverCont {
  margin-top: 1rem;
  text-align: center;
}

.coti_motivo_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
</style>