<template>
  <div
    class="contenedorCustomSelect"
    @blur="cambiarType($event)"
    @focus="cambiarType($event)"
  >
    <!-- focoFuera();  -->
    <div action="">
      <div class="entradaLabelCont">
        <span v-show="itemSelected.length > 0 && config.mostrarLabel">{{
          placeHolder
        }}</span>
      </div>
      <div
        class="caja_entrada"
        :class="
          stateInput == 'inicio'
            ? 'inicio'
            : stateInput == 'onFocus'
            ? 'onFocus'
            : stateInput == 'post'
            ? 'post'
            : stateInput == 'onError'
            ? 'onError'
            : ''
        "
      >
        <div
          class="selectCustomSimple"
          :class="cambioEstado ? 'active' : ''"
          @click="cambioEstado = !cambioEstado"
        >
          <div class="contenido-selectSimple">
            <span
              class="placeHolderCustomSelect caja_entrada_text"
              v-if="itemSelected.length == 0"
              >{{ config.requerido == "l" ? requerido : "" }}{{ placeHolder
              }}{{ config.requerido == "r" ? requerido : "" }}</span
            >
            <span class="tituloCustomSelectSimple caja_entrada_text" v-else>{{
              itemSelected[1]
            }}</span>
          </div>
          <div><img :src="arrowHeadSelect" alt="" /></div>
        </div>

        <div class="opciones" :class="cambioEstado ? 'active' : ''">
          <div class="opcionesCont">
            <div
              class="opcion"
              @click="setCambioSelect(item)"
              v-for="(item, index) in data"
              :key="index"
            >
              <div class="contenido-opcionSimple">
                <div class="textos">
                  <span class="tituloCustomSelectSimple caja_entrada_text">{{
                    item[1]
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <input type="hidden" name="pais" id="inputSelect" value="" /> -->
    </div>
    <div class="mensajesForm">
      <div class="mensajeError">
        <span v-show="stateInput == 'onError'">{{
          itemSelected.length == 0 ? mensajeError[0] : mensajeError[1]
        }}</span>
      </div>
      <div class="mensajeOkFocus">&nbsp;</div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  props: {
    data: Array,
    placeHolder: String,
    mensajeError: String,
    error: Boolean,
    requerido: String,
    config: Object,
    motivoRemesaPreSelected: String,
  },
  data() {
    return {
      arrowHeadSelect: require("../../assets/imgs/arrowHeadSelect.svg"),
      arrowHeadSelectError: require("../../assets/imgs/arrowHeadSelectError.svg"),
      itemSelected: [],
      cambioEstado: false,
      stateInput: "inicio",
      inFocus: false,
    };
  },
  computed: {
    ...mapState([]),
  },
  methods: {
    ...mapMutations([""]),
    // focoFuera() {
    //   this.cambioEstado = false;
    // },
    setCambioSelect(item) {
      this.cambioEstado = !this.cambioEstado;
      this.itemSelected = item;
    },
    cambiarType(evt) {
      let evento = evt.type;
      if (evento == "focus") {
        this.stateInput = "onFocus";
        this.inFocus = true;
      } else if (evento == "blur") {
        this.stateInput = "post";
        this.inFocus = false;
        this.cambioEstado = false;
        this.$emit("dispatchChangeData", this.itemSelected);
      }
    },
  },
  watch: {
    data() {
      // this.itemSelected = [];
      // console.log(this.data);
      if (this.data && this.data.length == 1) {
        this.itemSelected = this.data[0];
      }
    },
    error() {
      if (this.error) {
        this.stateInput = "onError";
      } else {
        if (this.inFocus) {
          this.stateInput = "onFocus";
        } else {
          this.stateInput = "post";
        }
      }
    },
    itemSelected() {
      this.$emit("dispatchChangeData", this.itemSelected);
    },
    motivoRemesaPreSelected() {
      if (this.motivoRemesaPreSelected != "") {
        let aux = this.data.findIndex((motivo) => {
          return motivo[0] == this.motivoRemesaPreSelected;
        });
        if (aux > -1) {
          this.itemSelected = this.data[aux];
        }
      }
    },
    // cambioEstado(){
    //   console.log(this.cambioEstado);
    // }
  },
  mounted() {
    // console.log(this.data);
    // if (this.data && this.data.length == 1) {
    //   this.itemSelected = this.data[0];
    // }
  },
};
</script>

<style >
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #f4f4f7;
  font-family: "Open Sans", sans-serif;
}

a {
  text-decoration: none;
}

/* .contenedorCustomSelectSimpleLabelCont {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 9px;
  color: var(--customG-787878);
  padding-left: 0.3rem;
  min-height: 9px;
} */
/* .selectboxSimple {
  width: 100%;
  margin: auto;
  position: relative;
  border-radius: 5px;
  max-height: 33px;
} */

.selectCustomSimple {
  /* background: #fff; */
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s ease all;
  padding: 0.2rem;
  position: relative;
  z-index: 100;
  /* border: 1.5px solid transparent; */
}

/* .selectCustom.active,
.selectCustom:hover {
  border: 1.5px solid #213f8f;
} */
.selectCustomSimple.active img {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
}

.selectCustomSimple.active:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  bottom: -30px;
}

.tituloCustomSelectSimple {
  font-family: "Roboto";
}


/* .opcionesCont {
  background-color: #fff;
  overflow-y: auto;
  max-height: 150px;
}
/* .option{
    cursor: pointer;
} */
.opcionesCont::-webkit-scrollbar {
  width: 4px;
  height: 90%;
  max-height: 90%;
  margin-top: auto;
  margin-bottom: auto;
}

.opcionesCont::-webkit-scrollbar-track {
  background-color: var(--customF-C4C4C4);
  border-radius: 10px;
}

.opcionesCont::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--customA-801B1C);
  max-height: 2rem;
} 
.opciones.active {
  display: block;
  animation: fadeIn 0.3s forwards;
}

/* .coti_motivo_remesa_active {
  display: block;
  animation: fadeIn 0.3s forwards;
} */

@keyframes fadeIn {
  from {
    transform: translateY(-20px) scale(0.5);
  }
  to {
    transform: translateY(0) scale(1);
  }
}

.contenido-opcionSimple {
  cursor: pointer;
}
.contenido-opcionSimple .textos {
  border-bottom: 1px solid #ececec;
  width: 100%;
}

.placeHolderCustomSelect {
  /* font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.5625rem;
  line-height: 11px; */
  color: var(--customG-787878);
}
.contenido-selectSimple {
  display: flex;
  justify-content: center;
  padding-left: 0.3rem;
}

/* @media screen and (max-width: 800px) {
  .selectboxSimple {
    width: 100%;
  }
} */
/* .inicio {
  background-color: var(--customE-E1E1E1);
  border: none;
  color: var(--customG-787878);
}
.onFocus {
  background-color: white;
  border: 1.5px solid var(--customC-614AD3);
  color: var(--customG-787878);
}
.post {
  background-color: white;
  border: 1.5px solid var(--customG-787878);
  color: var(--customG-787878);
}
.onError {
  background-color: white;
  border: 1px solid var(--customA-801B1C);
  color: var(--customA-801B1C);
} */
</style>