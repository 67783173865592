<template>
  <div class="volver_cont">
    <img :src="iconCotizadorVolver" alt="" srcset="" />
    <span @click="volver">
      <span class="textoSinDecoracion">&nbsp;</span>
      <span class="cotizDestinVolverText">{{
        t("app.comunes.btns.volver")
      }}</span>
    </span>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  data() {
    return {
      iconCotizadorVolver: require("../../assets/imgs/iconCotizadorVolver.svg"),
    };
  },
  methods:{
    volver(){
        this.$emit("volver_emit");
    }
  }
};
</script>

<style>
.volver_cont {
  text-align: center;
  cursor: pointer;
}
.cotizDestinVolverText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875rem;
  letter-spacing: 0.5px;
  text-decoration-line: underline;
  color: #787878;
}
</style>